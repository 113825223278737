<template>
	<modal class="NoxModalRecaptcha" name="NoxModalRecaptcha" modal-width="500" :width="500" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open">
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'getBotOrdersCSV'" v-html="$store.getters.getLanguageText('5.23', 0)"></span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxType == 'getBotOrdersCSV'">
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_recaptcha single">
									<a :href="noxBotOrdersCSVUrl" class="hide" id="nox_modal_info_bot_orders_csv_url" target="_blank"></a>
									<vue-recaptcha class="nox_recaptcha" ref="recaptcha" size="normal" theme="light" :sitekey="$store.state.noxRecaptchaSiteKey" @verify="verifyRecaptcha" @expired="resetRecaptcha"></vue-recaptcha>
									<div v-html="noxAlertRecaptcha"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common green" @click="axios('getBotOrdersCSV')" v-if="noxType == 'getBotOrdersCSV'" v-html="$store.getters.getLanguageText('1.1', 171)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import VueRecaptcha from 'vue-recaptcha'
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertRecaptcha: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxRecaptcha: '',
			noxBotOrdersCSVUrl: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxRecaptcha = '';
				this.noxBotOrdersCSVUrl = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'getBotOrdersCSV') { this.$parent.$parent.$parent.initRecaptcha(); }
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertRecaptcha = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 8); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 99); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 100); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 101); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			resetRecaptcha: function() {
				this.noxRecaptcha = '';
				this.$refs.recaptcha.reset();
			},
			verifyRecaptcha: function(token) {
				this.noxRecaptcha = token;
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'getBotOrdersCSV') {
						if (!_this.noxRecaptcha) {
							_this.noxAlertRecaptcha = _this.getError(4);
						}
						if (!_this.noxIsError) {
							_this.noxIsLoading = true;
							config.url = '/v2/account/bots/orders/csv';
							config.params = { id: _this.noxId, recaptcha: _this.noxRecaptcha };
						}
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getBotOrdersCSV') {
									if (data.data.url) {
										_this.noxBotOrdersCSVUrl = data.data.url;
										setTimeout(function() { document.getElementById('nox_modal_info_bot_orders_csv_url').click(); _this.close(); }, 100);
									}
									else {
										_this.noxAlert = _this.getError(6); _this.resetRecaptcha();
									}
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'getBotOrdersCSV') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'RECAPTCHA_IS_EMPTY') { _this.noxAlertRecaptcha = _this.getError(4); }
										else if (data.response.data.error == 'RECAPTCHA_NOT_VALID') { _this.noxAlertRecaptcha = _this.getError(4); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (type == 'getBotOrdersCSV') {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'ORDERS_NOT_FOUND') { _this.noxAlert = _this.getError(5); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
							if (type == 'getBotOrdersCSV') {
								_this.resetRecaptcha();
							}
						}).finally(function() {
							if (type == 'getBotOrdersCSV' && _this.noxBotOrdersCSVUrl) { return false; }
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalRecaptcha');
			}
		},
		components: {
			VueRecaptcha
		}
	}
</script>
